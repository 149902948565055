'use strict';

import announcementBar from '../../_common/js/modules/announcement';
import Cookies from '../../_common/js/modules/cookies';
import intlPhoneInput from '../../_common/js/modules/intlPhoneInput';
import mediumZoom from '../../_common/js/modules/mediumZoom';
import productBlocks from './modules/productBlocks';
import customLiveSearch from './modules/customLiveSearch';
import contactForm from '../../_common/js/modules/contactForm';
import gdprCookie from '../../_common/js/modules/gdprCookie';
import stickyMainMenu from '../../_common/js/modules/stickyMainMenu';
import imageWrapper from '../../_common/js/modules/imageWrapper';
import readMoreContent from './modules/readMoreContent';
import productTabsAnchors from './modules/productTabsAnchors';
import showMoreReviews from './modules/showMoreReviews';
import stickyComparisonElements from './modules/stickyComparisonElements';

( function( document, window ) {
    const cookiesGeneral = Cookies( 'HDM' );

    // Ready
    document.addEventListener( 'DOMContentLoaded', function() {
        stickyMainMenu();
        mediumZoom();
        productTabsAnchors();
        showMoreReviews();

        const comparisonPage = document.querySelector( '#comparison-page' );
        if ( comparisonPage ) {
            stickyComparisonElements();
        }

        const readMoreBtn = document.querySelector( '.read-more-btn' );
        if ( readMoreBtn ) {
            readMoreContent();
        }

        const customSearchForm = document.querySelector( '#custom-search-form' );
        if ( customSearchForm ) {
            customLiveSearch();
        }

        const productsTable = document.querySelector( '.products-table' );
        if ( productsTable ) {
            productBlocks();
        }

        const phoneInputs = document.querySelectorAll( 'input[type=tel]' );
        if ( phoneInputs.length ) {
            intlPhoneInput( phoneInputs );
        }

        const announcementBarElement = document.querySelector( '.announcement-bar' );
        if ( announcementBarElement ) {
            announcementBar( cookiesGeneral, announcementBarElement );
        }

        const gdprCookieElement = document.getElementById( 'tuna_gdpr__cookie' );
        if ( gdprCookieElement ) {
            gdprCookie( cookiesGeneral, gdprCookieElement );
        }

        const wpcf7Form = document.querySelector( '.wpcf7-form' );
        if ( wpcf7Form ) {
            contactForm();
        }

        const zoomWrap = document.querySelector( '.zoom-default:not([hidden]' );
        if ( zoomWrap ) {
            imageWrapper();
        }
    } );
}( document, window ) );
