/**
 * @module productBlocks
 */
module.exports = function() {
    const showRow = document.querySelector( '.show-row' );
    const showDetailsBtn = showRow.parentElement.querySelector( '.details-block p' );

    if ( getComputedStyle( showRow ).visibility !== 'hidden' ) {
        showDetailsBtn.textContent = showDetailsBtn.getAttribute( 'data-hide-block' );
        showDetailsBtn.nextElementSibling.classList.add( 'fa-sort-up' );
    }

    document.querySelectorAll( '.product-item .details-block' )
        .forEach( ( item ) => item.addEventListener( 'click', function() {
            const detailsBlock = this.querySelector( 'p' ),
                toggleRow = this.closest( '.product-item' ).querySelector( '.toggle-row' );

            toggleRow.classList.toggle( 'show-row' );
            toggleRow.classList.toggle( 'hide-row' );

            if ( toggleRow.classList.contains( 'show-row' ) ) {
                detailsBlock.textContent = detailsBlock.getAttribute( 'data-hide-block' );
                detailsBlock.nextElementSibling.classList.remove( 'fa-sort-down' );
                detailsBlock.nextElementSibling.classList.add( 'fa-sort-up' );
            } else {
                detailsBlock.textContent = detailsBlock.getAttribute( 'data-show-block' );
                detailsBlock.nextElementSibling.classList.remove( 'fa-sort-up' );
                detailsBlock.nextElementSibling.classList.add( 'fa-sort-down' );
            }
        } ) );
};
