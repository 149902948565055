/**
 * @module showMoreReviews
 */
module.exports = function() {
    // Check if the element with id 'tab-reviews' exists
    const reviewsTab = document.getElementById( 'tab-reviews' );

    // Proceed only if the reviewsTab element exists
    if ( reviewsTab ) {
        // Show more review button in Reviews Tab
        const loadMoreReviewsBtn = document.querySelector( '#load-more-reviews button' );
        const additionalReviews = document.getElementById( 'additional-reviews' );
        const reviewsTitle = document.querySelector( '.woocommerce-Reviews-title' );

        if ( loadMoreReviewsBtn ) {
            loadMoreReviewsBtn.addEventListener( 'click', function() {
                document.getElementById( 'load-more-reviews' ).classList.add( 'd-none' );
                additionalReviews.classList.remove( 'd-none' );
                additionalReviews.classList.add( 'd-block' );

                const totalReviews = parseInt( reviewsTitle.dataset.totalReviews, 10 ) || 0;
                const additionalReviewsCount = parseInt( reviewsTitle.dataset.additionalReviews, 10 ) || 0;
                const displayedReviews = totalReviews + additionalReviewsCount;

                reviewsTitle.textContent = `Reviews (1-${ displayedReviews } of ${ totalReviews })`;
            } );
        }
    }

    // Continue reading button in recent reviews
    const reviewButtons = document.querySelectorAll( '.continue-reading' );

    reviewButtons.forEach( function( button ) {
        button.addEventListener( 'click', function() {
            const reviewText = this.closest( '.review-text' );

            reviewText.innerHTML = reviewText.getAttribute( 'data-full-comment' );

            reviewText.classList.remove( 'short-comment' );
            button.classList.add( 'd-none' );
        } );
    } );
};
