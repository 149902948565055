/**
 * @module gdprCookie
 */

/**
 * @param {Cookie} Cookies - Object from Cookies-module
 * @param {object} gdprCookieContainer - DOM object with gdpr elements
 */

module.exports = function(Cookies, gdprCookieContainer) {

    const cookieName = 'eu_cookies_acknowledged';
    if (!Cookies.get(cookieName)) {
        gdprCookieContainer.classList.remove('d-none');
        gdprCookieContainer.classList.add('d-flex');
    }

    gdprCookieContainer.querySelector('.tuna_gdpr__cookie-button').addEventListener('click', (e) => {
        e.preventDefault();
        Cookies.create(cookieName, '1', '1y');
        const consentMode = {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            personalization_storage: 'granted',
            functionality_storage: 'granted',
            security_storage: 'granted',
        };
        setConsent(consentMode);
        gdprCookieContainer.remove();
    })

    document.querySelector('.cookie-accept-btn').addEventListener('click', (e) => {
        e.preventDefault();
        const checkboxNonNecessary = document.querySelector('.checkbox-non-necessary');
        let consentMode = {
            ad_storage: 'denied',
            analytics_storage: 'granted',
            personalization_storage: 'denied',
            functionality_storage: 'granted',
            security_storage: 'granted',
        };
        if ( checkboxNonNecessary.checked ) {
            consentMode = {
                ad_storage: 'granted',
                analytics_storage: 'granted',
                personalization_storage: 'granted',
                functionality_storage: 'granted',
                security_storage: 'granted',
            }
        }
        setConsent(consentMode);
        Cookies.create(cookieName, '1', '1y');
        gdprCookieContainer.remove();
    })

    function setConsent( consentMode ) {
        if (typeof gtag === "function") {
            gtag( 'consent', 'update', consentMode );
            localStorage.setItem( 'consentMode', JSON.stringify( consentMode ) );
        }
    }

}; // exports