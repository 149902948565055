/**
 * @module mediumZoom
 */

module.exports = function() {
    if ( typeof mediumZoom === 'function' && window.innerWidth >= 992 ) {
        // eslint-disable-next-line no-undef
        mediumZoom( document.querySelectorAll( '.zoom-default' ), {
            background: 'rgba(255, 255, 255, 0.95)',
        } );
    }
}; // exports
