/**
 * @module announcement
 */

/**
 * @param {Cookie} Cookies - Object from Cookies-module
 * @param {object} container - object with announcement elements
 * */

module.exports = function(Cookies, container) {
    container.querySelector('.announcement-bar__close').addEventListener('click', () => {
        Cookies.create('Hide_announcement', '1', '30d');
        container.remove();
        document.getElementById('navbarMobileMainMenu').classList.remove('announcement-bar-present');
    })
}; // exports

