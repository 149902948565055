/**
 * @module stickyMainMenu
 */

module.exports = function() {
    // Set custom class for scrolled navigation menu

    const nav = document.querySelectorAll( '.main_menu__nav' );

    // Fixes a bug
    window.scrollY !== 0 ? Object.keys( nav ).map( i => nav[i].classList.add( 'scrolled' ) ) : '';

    document.addEventListener( 'scroll', () => ( nav.length === 2 && window.scrollY > 85 ) || ( nav.length === 1 && window.scrollY !== 0 ) ? Object.keys( nav ).map( i => nav[i].classList.add( 'scrolled' ) ) : Object.keys( nav ).map( i => nav[i].classList.remove( 'scrolled' ) ) );
}; // exports