/**
 * @module imageWrapper
 */

module.exports = function() {
    if ( window.innerWidth >= 992 ) {
        document.querySelectorAll( '.zoom-default' ).forEach( ( elem ) => {
            const wrap = document.createElement( 'div' );
            const zoomLabel = document.createElement( 'div' );

            wrap.classList.add( 'wrap-block' );
            zoomLabel.classList.add( 'zoom-label' );
            elem.parentElement.insertBefore( wrap, elem );
            wrap.appendChild( elem );
            wrap.appendChild( zoomLabel );
        } );
    }
}; // exports
