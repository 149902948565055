/**
 * @module cookies
 */


/**
 * @class Cookies
 * @exports module:cookies
 * @alias module:cookies
 * @param {string} [namePrefix] - Prefix for cookie name
 * @param {string} [domain] - Default domain, without 'dot' E.G.: 'help-desk-migration.com', otherwise domain will be separated as 2-level domain from window.location
 * @returns {object}
 */

function Cookies (namePrefix, domain) {

    return {
        namePrefix: ( namePrefix ) ? namePrefix + '_' : '',
        defaultDomain: '.' + ( domain || window.location.hostname.split('.').splice(-2).join('.') ),
        /**
         *
         * @param name
         * @returns {*}
         */
        prepareName: function(name) {
            return this.namePrefix + name;
        },
        /**
         * Create cookie with value, expiration date and path
         * @param {string} value - Cookie value
         * @param {string} [expireInTime=] - Cookie expiration period. Format "1d", "2w", "3m", "4y"
         * @param {string} [path=/] - Path
         * @param {string} [domain=''] - Domain
         */
        create : function(name, value, expireInTime, path, domain) {

            var expires = "",
                diff = 0,
                hourInSeconds = 24*60*60*1000,

                expireParsed, expireParsedNumber,expireParsedLetter,
                expireParseRegex = /(\d+)([a-z])/gi;

            name = this.prepareName(name);
            path = path || '/';
            value = value || '';
            domain = domain || this.defaultDomain || '';

            expireParsed = expireParseRegex.exec(expireInTime);
            expireParsedNumber = parseFloat(expireParsed[1]);
            expireParsedLetter = expireParsed[2].toLowerCase();

            if (expireParsedLetter && expireParsedNumber) {
                var date = new Date();
                switch ( expireParsedLetter ) {
                    default:
                    case 'h' :
                        diff = expireParsedNumber * hourInSeconds;
                        break;
                    case 'd' :
                        diff = expireParsedNumber * 24 * hourInSeconds;
                        break;
                    case 'w' :
                        diff = expireParsedNumber * 7 * 24 * hourInSeconds;
                        break;
                    case 'm' :
                        diff = expireParsedNumber * 31 * 24 * hourInSeconds;
                        break;
                    case ( 'y' ) :
                        diff = expireParsedNumber * 365 * 24 * hourInSeconds;
                        break;
                }
                date.setTime(date.getTime()+diff);
                expires = "; expires="+date.toGMTString();
            }
            if (domain) {
                domain = '; domain='+domain;
            }
            else {
                domain = '';
            }
            document.cookie = name+"="+value+expires+"; path="+path+domain;
        },

        /**
         * Read cookie
         * @return {string|null} Value of the cookie
         */
        get : function(name) {
            var cookieRecord = '',
                nameEq = this.prepareName(name) + "=",
                cookies = document.cookie.split(';');

            for(var i=0; i<cookies.length; i++) {
                cookieRecord = cookies[i];
                while (cookieRecord.charAt(0) === ' ') {
                    cookieRecord = cookieRecord.substring(1,cookieRecord.length);
                }
                if (cookieRecord.indexOf(nameEq) === 0) {
                    return cookieRecord.substring(nameEq.length,cookieRecord.length);
                }
            }
            return null;
        },

        /**
         * Destroy cookie
         */
        destroy : function(name) {
            this.create(this.prepareName(name), '');
        },
        /**
         * Read Json cookie
         * @param name
         * @returns {any}
         */
        getJSON: function(name) {
            try {
                return JSON.parse(decodeURIComponent(this.get(name)));
            } catch (e) {
                return {};
            }
        }
    }
}

module.exports = Cookies;