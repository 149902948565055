/**
 * @module readMoreBtn
 */
module.exports = function() {
    const readMoreBtns = document.querySelectorAll( '.read-more-btn' );

    const iconUp = '<span class="material-icons text-primary fs-5">\n' + 'arrow_circle_up\n' + '</span>';
    const iconDown = '<span class="material-icons text-primary fs-5">\n' + 'arrow_circle_down\n' + '</span>';

    function updateButtonText( btn ) {
        const contentContainer = document.getElementById( btn.getAttribute( 'aria-controls' ) );
        let isCollapsed = contentContainer.classList.contains( 'show' );

        const ellipsis = contentContainer.previousElementSibling;
        ellipsis.classList.toggle( 'd-inline', ! isCollapsed );
        ellipsis.classList.toggle( 'd-none', isCollapsed );

        contentContainer.addEventListener( 'shown.bs.collapse', () => {
            btn.innerHTML = 'Read less ' + iconUp;
            isCollapsed = true;
            ellipsis.classList.toggle( 'd-none', isCollapsed );
        } );

        contentContainer.addEventListener( 'hidden.bs.collapse', () => {
            btn.innerHTML = 'Read more ' + iconDown;
            isCollapsed = false;
        } );
    }

    readMoreBtns.forEach( ( readMoreBtn ) => {
        updateButtonText( readMoreBtn );
        readMoreBtn.addEventListener( 'click', function( e ) {
            updateButtonText( e.target );
        } );
    } );
};
