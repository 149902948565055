/**
 * @module productTabsAnchors
 */
module.exports = function() {
    const tabsWrapper = document.querySelectorAll( '.wc-tabs-wrapper, .woocommerce-tabs' );

    tabsWrapper.forEach( function( wrapper ) {
        const tabs = wrapper.querySelectorAll( '.wc-tab, .woocommerce-tabs .panel:not(.panel .panel)' );
        tabs.forEach( function( tab ) {
            tab.style.display = 'none';
        } );

        const hash = window.location.hash.toLowerCase();
        const url = window.location.href;
        const tabsElement = wrapper.querySelector( '.wc-tabs, ul.tabs' );

        // Activate tabs if present
        activateTabs( hash, url, tabsElement );

        // Listen for button clicks always
        handleButtonClicks();
    } );

    // Activate tabs based on hash and URL
    function activateTabs( hash, url, tabsElement ) {
        if ( tabsElement && tabsElement.querySelectorAll( 'li' ).length > 0 ) {
            if ( hash.indexOf( 'comment-' ) >= 0 || hash === '#reviews' || hash === '#tab-reviews' ) {
                activateTab( tabsElement, 'li.reviews_tab a' );
            } else if ( url.indexOf( 'comment-page-' ) > 0 || url.indexOf( 'cpage=' ) > 0 ) {
                activateTab( tabsElement, 'li.reviews_tab a' );
            } else if ( hash === '#tab-additional_information' ) {
                activateTab( tabsElement, 'li.additional_information_tab a' );
            } else if ( hash === '#tab-pricing' ) {
                activateTab( tabsElement, 'li.pricing_tab a' );
            } else if ( hash === '#tab-description' ) {
                activateTab( tabsElement, 'li.description_tab a' );
            } else if ( hash === '' ) {
                // Don't show any tab
            } else {
                activateTab( tabsElement, 'li:first-child a' ); // Show first tab by default
            }
        }
    }

    // Listen for button clicks
    function handleButtonClicks() {
        document.addEventListener( 'click', function( event ) {
            const target = event.target.getAttribute( 'data-bs-target' );
            let tabAnchorSelector;

            if ( event.target.matches( '#anchor-list button' ) ) {
                tabAnchorSelector = '.description_tab a';
            } else if ( event.target.matches( 'button.view-pricing-link' ) ) {
                tabAnchorSelector = '.pricing_tab a';
            } else if ( event.target.matches( 'button.read-all-reviews-link' ) ) {
                tabAnchorSelector = '.reviews_tab a';
            }

            if ( tabAnchorSelector ) {
                const tabAnchor = document.querySelector( tabAnchorSelector );
                if ( tabAnchor ) {
                    tabAnchor.click();
                    setTimeout( () => {
                        const targetElement = document.querySelector( target );
                        if ( targetElement ) {
                            targetElement.scrollIntoView( { behavior: 'smooth' } );
                        }
                    }, 100 ); // 100 milliseconds delay
                    event.preventDefault();
                }
            }
        } );
    }

    // Activate a specific tab
    function activateTab( tabsElement, selector ) {
        const tab = tabsElement.querySelector( selector );
        if ( tab ) {
            tab.click();
            scrollToTabsElement( tabsElement );
        }
    }

    // Scroll to tabs element
    function scrollToTabsElement( element ) {
        if ( element ) {
            element.scrollIntoView( { behavior: 'smooth' } );
        }
    }
};
