/**
 * @module contactForm
 */

module.exports = function() {
    setTimeout( function() {
        const captchaBadget = document.querySelector( '.grecaptcha-badge' );
        let holder = '.wpcf7-form';

        if ( null !== document.querySelector( '.recaptcha-holder' ) ) {
            holder = '.recaptcha-holder';
        }

        if ( captchaBadget ) {
            captchaBadget.append( holder );
        }
    }, 1000 );

    const contactForm = {
        form: document.querySelector( 'form.wpcf7-form' ),
        submit: document.querySelector( '.wpcf7-submit' ),
        checkboxAcceptance: document.querySelector( 'form.wpcf7-form .wpcf7-acceptance:not(.optional) label' ),
        submitStatus: true,
        submitDisabled() {
            if ( this.submit ) {
                this.submit.setAttribute( 'disabled', false );
            }
        },
        submitEnabled() {
            if ( this.submitStatus ) {
                this.submit.removeAttribute( 'disabled' );
            }
        },
        checkForm() {
            if ( document.querySelector( '.wpcf7-not-valid-tip' ) || ( this.checkboxAcceptance && this.checkboxAcceptance.querySelector( 'input[name="your-acceptance"]' ).checked === false ) ) {
                this.submitDisabled();
            } else {
                this.submitEnabled();
            }
        },
        submitSwitch() {
            this.submitStatus = false;
            setTimeout( () => this.submitStatus = true, 5000 );
        },
    };

    if ( contactForm.checkboxAcceptance ) {
        contactForm.checkboxAcceptance.addEventListener( 'click', () => contactForm.checkForm() );
    }

    // When the button is clicked, the button is locked for 5 seconds.
    // Work only before submit email.
    document.querySelector( '.wpcf7' ).addEventListener( 'wpcf7beforesubmit', () => {
        contactForm.submitDisabled();
        contactForm.submitSwitch();
    } );

    [ 'submit', 'focusout', 'click', 'keypress', 'keydown', 'keyup' ].forEach( ( e ) => {
        contactForm.form.addEventListener( e, () => {
            setTimeout( () => contactForm.checkForm(), 10 );
            document.addEventListener( 'wpcf7invalid', contactForm.submitDisabled, false );
            document.addEventListener( 'wpcf7spam', contactForm.submitDisabled, false );
            document.addEventListener( 'wpcf7mailfailed', contactForm.submitDisabled, false );
        } );
    } );

    // Validation contact form
    function inputValidation( field, input ) {
        [ 'input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop', 'focusout' ].forEach( function( event ) {
            field.addEventListener( event, function( e ) {
                if ( input( this.value ) ) {
                    [ 'keydown', 'mousedown', 'focusout' ].indexOf( e.type ) >= 0 ? this.classList.remove( 'border-danger' ) : '';
                    this.oldValue = this.value;
                } else if ( this.hasOwnProperty( 'oldValue' ) ) {
                    this.classList.add( 'border-danger' );
                    this.value = this.oldValue;
                } else {
                    this.value = '';
                }
            } );
        } );
    }

    document.querySelectorAll( 'input[type="tel"]' ).forEach( ( input ) =>
        inputValidation( input, ( value ) => {
            return /^\+(\d{0,15})$/.test( value );
        } )
    );

    document.querySelectorAll( 'input[type="email"]' ).forEach( ( input ) =>
        inputValidation( input, ( value ) => {
            return /^([a-z0-9.@_-]{0,62})$/.test( value );
        } )
    );

    document.querySelectorAll( 'input[name="your-name"], input.only-text' ).forEach( ( input ) =>
        inputValidation( input, ( value ) => {
            if ( /^([-`'\p{L}\p{M}\s]{0,40})$/u.test( value ) ) {
                if ( /^([-`'\s]{0,40})$/u.test( value[ value.length - 1 ] ) ) {
                    if ( ! ( /^([\p{L}\p{M}])$/u.test( value[ value.length - 2 ] ) ) ) {
                        return false;
                    }
                    return true;
                }
                return true;
            }
        } )
    );

    document.querySelectorAll( 'textarea' ).forEach( ( textarea ) =>
        inputValidation( textarea, ( value ) => {
            return ! ( /^\s+$/.test( value ) );
        } )
    );

}; // exports
