/**
 * @module customLiveSearch
 */
module.exports = function() {
    const ajaxURL = '/wp-admin/admin-ajax.php';
    const searchResults = document.getElementById( 'search-results' );
    const showAllResults = document.getElementById( 'show-all-results' );
    const showAllResultsButton = document.getElementById( 'show-all-results-button' );
    const searchInput = document.getElementById( 'search-input' );
    const searchHint = document.getElementById( 'search-hint' );
    const subText = document.getElementById( 'sub-text' );
    let inputLength = 0;
    let showAllResultsVisible = false;

    document.getElementById( 'custom-search-form' ).addEventListener( 'submit', function( e ) {
        e.preventDefault();
        return false;
    } );

    searchInput.value = '';
    showAllResults.classList.add( 'd-none' );
    subText.classList.toggle( 'd-none', searchInput.value.length > 0 );

    searchInput.addEventListener( 'input', function() {
        const inputVal = this.value;
        const contentTypes = document.getElementById( 'content-types' ).value;

        const searchQuery = encodeURIComponent( inputVal );
        const contentTypesEncoded = encodeURIComponent( contentTypes );
        const filteredSearchResultsPage = `/search-results/?q=${ searchQuery }&content_types=${ contentTypesEncoded }`;
        showAllResultsButton.setAttribute( 'data-href', filteredSearchResultsPage );

        if ( inputVal.length !== inputLength ) {
            inputLength = inputVal.length;

            searchResults.classList.toggle( 'd-block', inputLength >= 3 );
            searchResults.classList.toggle( 'd-none', inputLength < 3 );
            searchHint.classList.toggle( 'd-block', inputLength > 0 && inputLength < 3 );
            searchHint.classList.toggle( 'd-none', ! ( inputLength > 0 && inputLength < 3 ) );

            if ( inputLength > 0 ) {
                subText.classList.add( 'd-none' );
                searchInput.style.boxShadow = 'none';
            } else {
                subText.classList.remove( 'd-none' );
                searchInput.style.boxShadow = '';
            }

            if ( inputLength >= 3 ) {
                searchResults.innerHTML = '<p class="fw-bold h5 mt-3 text-center mb-0 py-4">Loading...</p>';

                const xhr = new XMLHttpRequest();
                xhr.open( 'GET', `${ ajaxURL }?action=custom_search_ajax&q=${ encodeURIComponent( inputVal ) }&content_types=${ encodeURIComponent( contentTypes ) }`, true );

                xhr.onload = function() {
                    if ( xhr.status === 200 ) {
                        const numberOfResults = new DOMParser().parseFromString( xhr.responseText, 'text/html' ).querySelectorAll( 'li' ).length;

                        searchResults.innerHTML = xhr.responseText;

                        if ( numberOfResults >= 10 ) {
                            showAllResultsVisible = true;
                        } else {
                            showAllResultsVisible = false;
                        }

                        if ( inputLength >= 3 ) {
                            searchHint.classList.add( 'd-none' );
                            searchHint.classList.remove( 'd-block' );
                        } else {
                            searchResults.innerHTML = '';
                            searchResults.classList.add( 'd-none' );
                        }

                        updateShowAllResultsVisibility();
                    }
                };

                xhr.send();
            } else {
                searchResults.innerHTML = '';
                searchResults.classList.add( 'd-none' );
                updateShowAllResultsVisibility();
            }
        }
    } );

    function updateShowAllResultsVisibility() {
        if ( inputLength >= 3 && showAllResultsVisible ) {
            showAllResults.classList.remove( 'd-none' );
            showAllResults.classList.add( 'd-block' );
        } else {
            showAllResults.classList.remove( 'd-block' );
            showAllResults.classList.add( 'd-none' );
        }
    }

    showAllResultsButton.addEventListener( 'click', function( e ) {
        e.preventDefault();
        const searchQuery = encodeURIComponent( searchInput.value );
        const contentTypes = encodeURIComponent( document.getElementById( 'content-types' ).value );
        const searchResultsPage = '/search-results/';
        const filteredSearchResultsPage = `${ searchResultsPage }?q=${ searchQuery }&content_types=${ contentTypes }`;

        if ( searchResults.querySelectorAll( 'li' ).length > 0 ) {
            window.location.href = filteredSearchResultsPage;
        }
    } );
};
