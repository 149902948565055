/**
 * @module stickyComparisonElements
 */
module.exports = function() {
    let comparisonPlatforms;
    const comparisonPlatformsTwo = document.querySelector( '.comparison-platforms-2' );
    const comparisonPlatformsThree = document.querySelector( '.comparison-platforms-3' );
    const comparisonMenu = document.querySelector( '.comparison-menu' );
    const platformModal = document.getElementById( 'platformModal' );

    function removeStickyPosition() {
        if ( window.innerWidth >= 768 ) {
            comparisonPlatforms.classList.remove( 'position-sticky' );
            comparisonPlatforms.style.zIndex = '';
            comparisonMenu.classList.remove( 'position-sticky' );
            comparisonPlatforms.style.top = '';
            comparisonMenu.style.top = '';
        }
    }

    function addStickyPosition() {
        if ( window.innerWidth >= 768 ) {
            comparisonPlatforms.classList.add( 'position-sticky' );
            comparisonPlatforms.style.zIndex = '1000';
            comparisonMenu.classList.add( 'position-sticky' );
            comparisonPlatforms.style.top = '5rem';
            comparisonMenu.style.top = '5.5rem';
        }
    }

    if ( comparisonPlatformsTwo ) {
        comparisonPlatforms = comparisonPlatformsTwo;
        addStickyPosition();
    }

    if ( comparisonPlatformsThree && window.innerWidth >= 992 ) {
        comparisonPlatforms = comparisonPlatformsThree;
        addStickyPosition();
    }

    if ( platformModal && comparisonPlatformsTwo ) {
        platformModal.addEventListener( 'show.bs.modal', function() {
            removeStickyPosition();
        } );

        platformModal.addEventListener( 'hidden.bs.modal', function() {
            addStickyPosition();
        } );
    }
};
